<template>
    <div v-if="usePortal" class="header-container">
        <h2>{{ this.$route.meta.title }}</h2>
        <div v-if="!isLoggedIn">
            You are logged out.
        </div>
        <div v-else id="home-loggedin">
            <div class="quickmenu">
                <div class="menu-label">Quick Links</div>
                <div class="menu-item">
                    <router-link :to="{  name: 'TimetableView'}">Timetables</router-link>
                </div>
                <div class="menu-item">
                    <router-link :to="{  name: 'CampusMapView'}">Campus Map</router-link>
                </div>
            </div>
        </div>
    </div>
    <div v-else >
        <div v-if="isLoggedIn">
            <div class="header-container">
                <h2>{{ this.$route.meta.title }}</h2>
            </div>
            <the-alert />
            <div>
                <span v-html="$strings('main.welcome')"></span>
            </div>
            <section id="content" class="welcome_area p_120" v-if="homeScreenTiles && homeScreenTiles.length">
                <hr>
                <div class="container">
                    <div class="row justify-content-center" id="masonry">
                        <div class="col-lg-4 my-1 px-1 py-1" v-for="(tile, index) in homeScreenTiles" :key="tile.name">
                            <component :is="tile.name" @vue:mounted="renderGridOnMount(index)" @vue:updated="renderGridOnUpdate()" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <div class="header-container">
                <h2>{{ this.$route.meta.titlenotloggedin }}</h2>
            </div>
            <div>
                <span v-html="$strings('main.welcomeNoAuth')"></span>
            </div>
        </div>
        <the-alert />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAuthenticationStore } from '@/stores/authentication.module';
import { useAlertStore } from '@/stores/alert.module';
import { useUserSettingsStore } from '@/stores/usersettings.module';
import { getHomeTiles, importHomeTiles } from '@/helpers/usersettings.helper';
import TheAlert from '@/components/TheAlert.vue';
import Masonry from "masonry-layout";

let componentNames = {};
(async () => {
    componentNames = await getHomeTiles('all');
})();

export default {
    name: 'MainView',
    components: {
        TheAlert,
    },
    data() {
        return {
            homeScreenTiles: [],
            masonryGrid: undefined
        };
    },
    beforeCreate() {
        this.$options.components = importHomeTiles(componentNames);
        this.$options.components.TheAlert = require("@/components/TheAlert.vue").default;
    },
    created() {
        this.initialiseUI();
    },
    computed: {
        ...mapState(useAuthenticationStore, ['isLoggedIn', 'isStaff', 'isStudent']),
        ...mapState(useUserSettingsStore, ['myHomeScreenTiles']),
    },
    methods: {
        ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
        ...mapActions(useUserSettingsStore, ['setMyHomeScreenTiles']),
        usePortal() {
            return true;
        },
        async initialiseUI() {
            let loader = this.$loading.show();
            if(this.isLoggedIn) {
                try {
                    if (this.myHomeScreenTiles === undefined) {
                        this.setMyHomeScreenTiles(await getHomeTiles(this.isStaff ? 'staff' : this.isStudent ? 'student' : 'all'));
                    }
                    this.homeScreenTiles = this.myHomeScreenTiles;
                } catch (error) {
                    this.showErrorAlert('Error: Unable to retrieve homescreen tiles');
                }
            }
            loader.hide();
        },
        renderGridOnMount(index) {
            if (index === this.homeScreenTiles.length-1){
                if (this.masonryGrid === undefined) {
                    const row = document.querySelector("#masonry");
                    this.masonryGrid = new Masonry(row, { percentPosition: true, transitionDuration: 0 });
                    this.masonryGrid.layout();
                }
            }
        },
        renderGridOnUpdate() {
            if (this.masonryGrid !== undefined) {
                this.masonryGrid.layout();
            }
        }
    }
}
</script>

<style scoped>
.quickmenu {
    padding: 1em;
    border: solid 1px var(--clr-nu-dark-blue);
    border-radius: 10px;
    width: 33%;
}
.menu-item {
    padding-left: 1em;
}

@media (max-width: 890px) {
    .quickmenu {
        width: 50%;
    }
}
@media (max-width: 370px) {
    .quickmenu {
        width: 100%;
    }
    .menu-item {
        padding-top: 0.5em;
        padding-left: 0;
        font-weight: bold;
    }
}
</style>
