<template>
    <div v-if="displayExams">
        <div v-if="hasExamData" class="ttable-width exam-display">
            <div><h4>Exams for this Academic Period</h4></div>
            <div v-for="(exam, i) in getExamData" :key="i">
                <!--div><pre>{{ exam }}</pre></div-->
                <div class="card mb-2">
                    <div :class="`card-title border border-3 ps-2 ${examProximityColour(exam.date, exam.time)}`">{{ exam.date }} {{ exam.time }}</div>
                    <div class="container">
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Module:</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.moduleCode }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Title</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.title }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Date:</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.date }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Time:</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.time }}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Duration:</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.duration }} ({{ exam.durationInMinutes }} min)</div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Location:</div>
                            <div class="col-8 col-sm-9 col-md-10">{{ exam.location }}
                                <div v-if="exam.locationBuildingExternalID">
                                    <router-link
                                        :to="{  name: 'CampusMapView',
                                                query: {
                                                    buildingId: exam.locationBuildingExternalID,
                                                    type: 'bld'
                                                }
                                    }"> View on Map</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-sm-3 col-md-2 bg-light text-end">Seat No:</div>
                            <div v-if="exam.seatNumber" class="col-8 col-sm-9 col-md-10">{{ exam.seatNumber }}</div>
                            <div v-else class="col-8 col-sm-9 col-md-10">-</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapState} from "pinia";
    import {useMyTimetableStore} from "@/stores/timetables.module";
    import {useAlertStore} from "@/stores/alert.module";
    import {useAuthenticationStore} from "@/stores/authentication.module";
    import {useRoute} from "vue-router";

    export default {
        name: "ExamTimetables",
        components: {
        },
        created() {
            this.initialiseExamTT();
            this.oneHourInMs = 60 * 60 * 1000;
            this.oneDayInMs = this.oneHourInMs * 24;
        },
        mounted() {
        },
        computed: {
            ...mapState(useMyTimetableStore, ['examdata']),
            hasExamData() {
                return this.examdata?.academicPeriod?.exams?.length;
            },
            getExamData() {
                return this.examdata?.academicPeriod?.exams || [];
            },
            displayExams() {
                // set to true to display exam timetable
                return false;
            },
        },
        methods: {
            ...mapActions(useMyTimetableStore, ['getTimetable']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            ...mapState(useAuthenticationStore, ['isStudent', 'isAdmin']),
            fetchExams() {
                // set to true to fetch exam timetable
                return false;
            },
            async initialiseExamTT() {
                if (this.fetchExams()) {
                    try {
                        // - set this to true to allow the hard coding of a student login set in timetables.module.js to see exam
                        let fakelogin = false;
                        if (this.isAdmin) {
                            let route = useRoute();
                            if (route.query.sno) {
                                fakelogin = true;
                                //console.log("exam faking login");
                            }
                        }
                        if (this.isStudent || fakelogin) {
                            await this.getTimetable(2);
                        }
                    }
                    catch (error) {
                        //console.log("Well I tried");
                        this.showErrorAlert('Error: Unable to retrieve exam timetable (from exam timetable)');
                    }
                } else {
                    //console.log("Not getting exam data");
                }
            },
            getExamTime() {
                let i = 0;
                let retval = "";
                //console.log("in getTime");
                if (this.examdata?.academicPeriod?.exams) {
                    //console.log("in getTime");
                    let timeparts = this.examdata.academicPeriod.exams[i].time.split(" ");
                    retval = timeparts[0];
                }
                return retval;
            },
            examProximityColour(date, time) {
                // - bg-success bg-warning bg-danger bg-secondary
                let dateNow = new Date();
                let examDate = new Date(date + " " + time);
                let myClass = "bg-info bg-gradient border-info";
                let timeToExam = examDate - dateNow;
                if (timeToExam < 0) { // - in the past
                    myClass = "bg-secondary bg-gradient border-secondary text-light";
                } else if (timeToExam <= this.oneHourInMs) {
                    myClass = "bg-danger bg-gradient border-danger text-light";
                } else if (timeToExam <= this.oneDayInMs) {
                    myClass = "border-warning";
                }
                return myClass; //date + " bg-warning " + time;
            }
        }
    }
</script>


<style scoped>

    @import '../../assets/mytimetable.css';
    .exam-display {
        font-size: 0.9rem;
    }
    .exam-display .card-title {
        font-size: 1.2em;
    }
    .exam-header {
        background-color: #ffc107;
    }
</style>
