<template>
    <div class="p-1">
        <i class="bi bi-patch-exclamation"></i>&nbsp;{{ availablePCs == 0 ||
                                                        openingStatus == 2 ||
                                                        bookingStatus == 2 || 
                                                        parentBooked ? 'No' : availablePCs }} PCs available
    </div>
    <div class="p-1" v-if="showOpeningTimes">
        <i class="bi bi-clock"></i>&nbsp;{{ openingStatusText }}
    </div>
    <div class="p-1" v-if="showMapLink">
        <i class="bi bi-geo-alt-fill"></i>&nbsp;<router-link
                                                    :to="{name: 'CampusMapView',
                                                        query: {
                                                            buildingId: buildingId,
                                                            type: 'clu',
                                                            facilityId: clusterId }}"> View on Map
                                                </router-link>
    </div>
</template>

<script>
    export default {
        name: 'ClusterStats',
        props: {
            clusterId: {
                type: String,
                default: ""
            },
            buildingId:  {
                type: String,
                default: ""
            },
            availablePCs: {
                type: Number,
                default: 0
            },
            openingStatusText: {
                type: String,
                default: ""
            },
            openingStatus: {
                type: Number,
                default: 2
            },
            bookingStatus: {
                type: Number,
                default: 0
            },
            parentBooked: {
                type: Boolean,
                default: false
            },
            showOpeningTimes: {
                type: Boolean,
                default: false
            },
            showMapLink: {
                type: Boolean,
                default: false
            },
        },
    };
</script>

<style scoped>
</style>
