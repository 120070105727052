<template>
    <div v-if="errorsEncountered()" class="error-help alert alert-info ttable-width">
        If you are experiencing repeated errors accessing your timetable here, please ensure you are signed in correctly (you can see your name in the top right corner) and you have internet connection.<br />
        If still required, you can view the <a href="https://timetables.ncl.ac.uk/" target="_webtt">Web Timetables</a><br />
        Please make a report to <a href="https://nuservice.ncl.ac.uk/" target="_service_desk">Service Desk</a> including as much detail as you can, with screenshots if possible.
    </div>
    <div id="div-1">
        <!-- navigation Plus/Minus 1 week -->
        <div id="tt-nav-weeks" class="ttable-width" v-if="getThisWeek">
            <div id="tt-month-display" class="rowish">{{ weekMonthOf }}</div>
            <div id="tt-nav-buttons">
                <span @click="incWeekNo(-5)" class="as-button left prevent-select"><span><i class="bi bi-chevron-double-left"></i></span></span>
                <span @click="incWeekNo(-1)" class="as-button left prevent-select"><i class="bi bi-chevron-left"></i></span>
                <span>Week {{ weekNumber }}</span>
                <span @click="incWeekNo(1)" class="as-button right prevent-select"><i class="bi bi-chevron-right"></i></span>
                <span @click="incWeekNo(5)" class="as-button right prevent-select"><i class="bi bi-chevron-double-right"></i></span>
            </div>
        </div>



        <section v-if="(showTimetableLayout && thisWeekTT) || environment === 'dev'" id="timetable-output">

            <!-- text/screen reader display -->
            <div id="xtt-text" class="">
                <div>Text Only Version (Screen Reader Friendly)</div>
                <div class="col-2">
                    <button class="col btn btn-primary" id="text-version-toggler" v-on:click="toggleTextVersion">{{getToggleText()}}</button>
                </div>
                <div v-if="showTextTT">
                    <div :id="'xtt-'+weekDaysLong[di]" class="xtt-cell xtt-day xtt-daylist" v-for="(day, di) in weekDays" :key="di">
                        <div v-if="todayHasActivity(di)" class="ifHasActivity">
                            <div class="xtt-dayheader">{{day}} {{ getDayDate(di) }}</div>

                            <div class="xtt-daydata">
                                <div v-for="(hour, hi) in padHours" :key="hi" class="xtt-hour-entry">
                                    <div v-if="anythingThisHour(di, hour, true,1)" class="att-hour-entry">
                                        <div v-for="(slot, sid) in getSlots()" :key="sid" :class="` xhour-slot xhour-slot-${slot}`">
                                            <div v-if="hasTTEntry(di, hour, slot)" class="xhasTT rowy flex-nowrap">
                                                <div v-for="(myActivity, ce) in getTTEntries(di, hour, slot)" :key="ce"  class="getTT col">
                                                    <div v-if="showAct(myActivity,1)"
                                                         :class="`xbg-gradient xtt-activity xtt-content activity-type-${myActivity.ActivityType} xactivity-length-2 xactivity-start-${myActivity.StartMin}`"
                                                         :style="`background-colory:#FFF; colory:#333; widthy:${myActivity.width}%; left:${ce}px;`"
                                                         :data-day = "`${di}`"
                                                         :data-hour = "`${hour}`"
                                                         :data-min = "`${slot}`"
                                                         :data-id = "`${myActivity.id}`"
                                                         :data-entries = "`${myActivity.entries}`"
                                                         :data-width = "`${myActivity.width}`"
                                                         :data-posleft = "`${myActivity.posLeft}`"
                                                    >
                                                        <div class="col activity-start-time activity-header bg-gradient inlist">
                                                            {{ myActivity.ActivityLong }}<span class="activity-startend">{{ myActivity.StartTime}} - {{ myActivity.EndTime}} ({{myActivity.DurationHours}})</span>
                                                        </div>
                                                        <div class="reader-entry">
                                                            <div class="reader-row">
                                                                <div class="col actmod" ><span class="fst-italic act-title">Name:</span> {{myActivity.Name}}</div>
                                                            </div>
                                                            <div class="reader-row">
                                                                <div class="col actdesc"><span class="fst-italic act-title">Description:</span> {{myActivity.Description}}</div>
                                                            </div>
                                                            <div class="reader-row" v-if="hasLocation(myActivity.Location.Description)">
                                                                <div class="col actloc">
                                                                    <span class="fst-italic act-title">Location:</span>
                                                                    {{myActivity.Location.Name}} - {{myActivity.Location.Description}}
                                                                    <span class="act-maplink" v-if="myActivity.Location.Link" v-html="`${myActivity.Location.Link}`"></span>
                                                                </div>
                                                            </div>
                                                            <div class="reader-row" >
<!--                                                                <div class="col actstaff">{{myActivity.StaffMember.Staff_Name}} <span class="staff-description">{{myActivity.StaffMember.Staff_Description}}</span></div>-->
                                                                <div class="col actstaff" v-html="getAllStaff(myActivity)"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

<!--
                    <div id="xtt-nav-weeks" class="ttable-width" v-if="getThisWeek">
                        <div id="xtt-nav-buttons">
                            <span @click="incWeekNo(-5)" class="as-button left prevent-select"><span><i class="bi bi-chevron-double-left"></i></span></span>
                            <span @click="incWeekNo(-1)" class="as-button left prevent-select"><i class="bi bi-chevron-left"></i></span>
                            <span>Week {{ weekNumber }}</span>
                            <span @click="incWeekNo(1)" class="as-button right prevent-select"><i class="bi bi-chevron-right"></i></span>
                            <span @click="incWeekNo(5)" class="as-button right prevent-select"><i class="bi bi-chevron-double-right"></i></span>
                        </div>
                        <div id="xtt-month-display" class="rowish">{{ weekMonthOf }}</div>
                    </div>
-->

                </div>
                <div></div>
            </div>



            <div id="timetable-layout" class="ttable-width" v-on:touchstart="handleTouchStart" v-on:touchend="handleTouchEnd">
                <!-- Popup with details shows when a user 'clicks' a timetable entry -->
                <div id="activity-details" class="dropshadow" v-on:click="stopProp">
                    <div id="close-x" v-on:click="closeActivityDetails"><i class="bi-x-circle"></i></div>
                    <div></div>
                    <div v-for="(activity, i) in ActivityDetails" :key="i">
                        <div v-if="activity.Show" class="row act-detail-row">
                            <div class="col-sm-5 text-sm-end text-start col-12 detail-name "><div class="detail-name-content">{{ activity.Name }}:</div></div><div class="col" v-html="activity.Val"></div>
                        </div>
                    </div>
                </div>

                <!-- Display hours of the day alongside the main timetable layout -->
                <div id="timecol" class="tt-cell tt-hours tt-timelist">
                    <!--<div class="tt-timelist">-->
                        <div class="tt-cell">&nbsp;</div>
                        <div class="tt-cell tt-hour" v-for="(hour, i) in padHours" :key="i"  @mouseover="hover = true" @mouseleave="hover = false">
                            {{ hour }}
                        </div>
                    <!--</div>-->
                </div>

                <!-- di is 0 (Mon) - 6 (Sun). Can be used as lookup WeekAndDay.DayInWeek -->
                <div :id="'tt-'+weekDaysLong[di]" class="tt-cell tt-day tt-daylist "  :class="[ttDayClassObject, `tt-col-${oddOrEven(di + weekNumber)}`]" v-for="(day, di) in weekDays" :key="di">
                    <!-- DAYS columns -->
                    <div class="tt-cell tt-dayhead row mx-0">
                        <div class="col px-0 dayDayHeader">{{ day }} {{ getDayDate(di) }}</div>
                    </div>
                    <!-- HOURS cells -->
                    <div class="tt-cell tt-hour" v-for="(hour, hi) in padHours" :key="hi"  @mouseover="hover = true" @mouseleave="hover = false"
                         :id="`hourblock-${di}-${hour}`" :class="`tt-col-${oddOrEven(di + weekNumber)}`"
                         :data-dayhour = "`${di}-${hour}`"
                    >
                        <div v-if="anythingThisHour(di, hour, false, 2)" class="tt-hour-entry">
                            <div v-for="(slot, sid) in getSlots()" :key="sid" :class="` hour-slot hour-slot-${slot}`">
                                <div v-if="hasTTEntry(di, hour, slot)" class="hasTT row flex-nowrap">
                                    <div v-for="(myActivity, ce) in getTTEntries(di, hour, slot)" :key="ce"  class="getTT col">
                                        <div v-if="showAct(myActivity,2)"
                                            v-on:click="getActivityDetails"
                                            :class="`testActivity bg-gradient tt-activity tt-content activity-type-${myActivity.ActivityType} activity-length-${myActivity.Duration} activity-start-${myActivity.StartMin}`"
                                            :style="`background-colory:#FFF; colory:#333; widthy:${myActivity.width}%; left:${ce}px;`"
                                            :data-day = "`${di}`"
                                            :data-hour = "`${hour}`"
                                            :data-min = "`${slot}`"
                                            :data-id = "`${myActivity.id}`"
                                            :data-entries = "`${myActivity.entries}`"
                                            :data-width = "`${myActivity.width}`"
                                            :data-posleft = "`${myActivity.posLeft}`"
                                        >
                                            <div class="module-code bg-gradient activity-header ingrid" >
                                                {{ myActivity.ActivityLong }}
                                                <span class="activity-startend activity-timedisplay">{{ myActivity.StartTime}} - {{ myActivity.EndTime}}</span>
                                                <span class="activity-duration activity-timedisplay">{{ myActivity.StartTime}} - {{myActivity.DurationHours}}</span>
                                            </div>
                                            <div class="activity-front-details">
                                                <div class="activity-name activity-content">
                                                    <div class="activity-content">
                                                        {{ myActivity.Name }}
                                                    </div>
                                                </div>
                                                <div class="activity-type activity-location" v-if="myActivity.Location.Name">
                                                    <div class="activity-content">
                                                        @ - {{ myActivity.Location.Name }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="`activity-type-${myActivity.ActivityType} end-activity`" ></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="testActivity" >&nbsp;</div>
                            </div>
                        </div>
                    </div> <!-- /Hours -->
                </div> <!-- / Day layout (looped)-->

            </div>
            <div v-if="hasDataFetch" class="last-data-fetch">
                Last data fetch: {{ getLastFetch() }}
            </div>
        </section>

        <!--
        <div id="all-data" class="nodisplay">
            <pre>
            {{GetRawData}}
            </pre>
        </div>
        -->
    </div>
</template>


<script>
/*:style="`background-color:${currentEntry.Module.displayColours.bgCol}; color:${currentEntry.Module.displayColours.textCol}; top:${currentEntry.StartMin}px`"*/
/*:style="`color:${currentEntry.Module.displayColours.bgCol}; background-color:${currentEntry.Module.displayColours.textCol};`"*/
/**
 * on load, or change of week-number (need a widget for prev/next week) - done
 * 1. filter timetable data on week number - done
 * 2. iterate the result and build an object array of 'activities' for that week on day and hour (acts{ d:{ h:{} } }) -done
 * 3. re-display the timetable on the page (need to tie in the display with the updated array) - done
 */

/*

 */
    import { mapActions, mapState } from "pinia";
    import {useAlertStore} from "@/stores/alert.module";
    import { useMyTimetableStore } from '@/stores/timetables.module';
    import {useWeeksOfYearStore} from "@/stores/weeksofyear.module";
    import {useTimetableStore} from "@/stores/timetable.module";
    import {useCardStore} from "@/stores/card.module";
    import {useApplicationSettingsStore} from "@/stores/applicationsettings.module";
    import moment from "moment-timezone";

    export default {
        name: "TimetableData",
        components: {
        },
        created() {
            this.ttdata = {}; //ref({});
            this.firstHourOfDay = 8;
            this.lastHourOfDay = 22;
            this.hourFirst = 8;
            this.lastHourOfDay = 22;
            this.padHours = ["08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"];
            // - two arrays to assign to this.weekDays depending on whether week has a weekend activity
            this.shortWeek = ["Mon", "Tues", "Wed", "Thu", "Fri"];
            this.longWeek = ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const touchStartX = 0;
            const touchEndX = 0;
            const touchStartY = 0;
            const touchEndY = 0;
            this.hasScrolled = false;
            //currentEntry used for TimeTable population
            this.currentEntry;
            // - set initial week for timetable display (null for normal activity)
            this.weekNumber = null;
            this.weekDate = null;
            this.hasWeekendActivity = false;
            this.initialiseUI();
            this.initTTData();
            this.weekMonthOf = "";
            this.lastActivityEndTime = null;
            this.isShiftDown = false;
            this.showTextVersion = false;
            this.activitiesPerDay = [];
            this.tterrors = 0;

        },
        mounted() {
            //console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<MOUNTED>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            //this.setDayWidth(this.hasWeekendActivity);
            // - listen for swipes on timetable-output or timetable-layout
            //document.addEventListener('scroll', this.recordScroll, false);
            //document.addEventListener('resize', this.closeActivityDetails(), false);
            //window.addEventListener('resize', this.closeActivityDetails());
            document.addEventListener('keyup', this.keyUp, false);
            document.addEventListener('keydown', this.keyDown, false);
        },
        data: function() {
            return {
                firstHourOfDay: 8,
                lastHourOfDay: 22,
                hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
                padHours: ["08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"],
                weekDays: ["M", "T", "W", "T", "F", "S", "S"],
                weekDaysLong: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday",],
                dayDate: [],
                hover: false,
                UIInitialised: false,
                weekNumber: null,
                weekDate: null,
                // - Month display at top left of timetable
                // weekMonthOf: "",
                // - used for popup when clicking on a ttable entry / activity
                ActivityDetails: [],
                hasWeekendActivity: false,
                showTextVersion: true,
            }
        },
        computed: {
            ...mapState(useMyTimetableStore, ['myRawData', 'timetabledata', 'Activities', 'weekActivities']),
            ...mapState(useWeeksOfYearStore, ['academicweeks']),
            ...mapState(useApplicationSettingsStore, ['environment']),
            ...mapState(useCardStore, ['cards']),
            showTimetableLayout() {
                let retVal = true;
                //console.log("Showing tt: " + retVal);
                return retVal;
            },
            thisWeekTT() {
                if (this.weekNumber === null) {
                    this.GetCurrentWeekNumber;
                }
                let wkno = this.weekNumber;
                this.getTTData(wkno);
                let retval = this.timetabledata?.thisweek ? this.timetabledata.thisweek : false;
                this.buildTTArray(retval);
                return retval;
            },
            GetCurrentWeekNumber() {
                return this.weekNumber; //this.academicweeks?.thisWeek ? this.academicweeks.thisWeek[0].Week : 0;
            },
            GetRawData() {
                return this.myRawData ? this.myRawData : "spunky potatoes";
            },
            ttDayClassObject() {
                /** Sets class on day columns to narrow if weekend has activity **/
                return {
                    wideDay: !this.hasWeekendActivity
                }
            },
            showTextTT() {
                return this.showTextVersion;
            },
        },
        watch: {
        },
        methods: {
            ...mapActions(useMyTimetableStore, ["getTTData", "getModuleInfo", "getErrors"]),
            ...mapActions(useTimetableStore, ['act_translate']),
            ...mapActions(useWeeksOfYearStore, ['getWeeks', 'setWeekToNumber']),
            ...mapActions(useAlertStore, ['showInformationAlert', 'showErrorAlert']),
            ...mapActions(useCardStore, ['getCards']),
            errorsEncountered() {
                //console.log("*!*!*!*! Checking for TT errors !*!*!*!*! " + this.tterrors);
                return this.tterrors > 0;
            },
            hasDataFetch() {
                return this.timetabledata?.lastDataFetched !== undefined;
            },
            getLastFetch() {
                return this.timetabledata.lastDataFetched;
            },
            todayHasActivity(day) {
                //console.log("Today? " + day);
                day = parseInt(day);
                let retVal = false;
                if (this.activitiesPerDay[day] > 0) {
                    //console.log("ttdata: ", this.ttdata[day]);
                    retVal = true;
                }
                return retVal;
            },
            toggleTextVersion() {
                this.showTextVersion = !this.showTextVersion;
            },
            getToggleText() {
                let toggleText = "Show";
                if (this.showTextVersion) {
                    toggleText = "Hide";
                }
                return toggleText;
            },
            getThisWeek() {
                return this.timetabledata?.thisweek ? this.timetabledata.thisweek : false;
            },
            showAct(activity, loc=0) {
                return !activity.isFake;
            },
            recordScroll(event) {
                this.hasScrolled = true;
            },
            keyUp(event) {
                if (event.key === "Shift") {
                    this.isShiftDown = false;
                }
                if (event.key === "ArrowRight") {
                    if (this.isShiftDown) {
                        this.incWeekNo(5);
                    } else {
                        this.incWeekNo(1);
                    }
                } else if (event.key === "ArrowLeft") {
                    if (this.isShiftDown) {
                        this.incWeekNo(-5);
                    } else {
                        this.incWeekNo(-1);
                    }
                }
            },
            keyDown(event) {
                if (event.key == "Shift") {
                    this.isShiftDown = true;
                }
            },
            stopProp(e) {
                // - added this to stop the details pop-up closing when clicked in (Screen readers need the behaviour).
                // - Only closes when window clicked, or the close button on the pop-up.
                e.stopPropagation();
            },
            handleTouchStart(event) {
                event.stopPropagation();
                return;
                // eslint-disable-next-line
                /* event.stopPropagation();
                this.touchStartX = event.changedTouches[0].screenX;
                this.touchStartY = event.changedTouches[0].screenY; */
                //console.log("Touch start ", event.target);
            },
            handleTouchEnd(event) {
                //event.stopPropagation();
                //return;
                // eslint-disable-next-line
                /* this.touchEndX = event.changedTouches[0].screenX;
                this.touchEndY = event.changedTouches[0].screenY;
                let xDistance = Math.abs(this.touchEndX - this.touchStartX);
                let yDistance = Math.abs(this.touchEndY - this.touchStartY);
                if ( xDistance < yDistance || this.ActivityDetailsShowing) {
                    this.hasScrolled = false;
                    //console.log("ignoring touch end");
                } else {
                    //console.log("x: " + xDistance + " y: " + yDistance);
                    this.checkDirection();
                } */
            },
            checkDirection() {
                //console.log("Checking direction " + this.touchStartX + "  " + this.touchEndX);
                if (this.touchEndX < this.touchStartX) this.incWeekNo(1);
                if (this.touchEndX > this.touchStartX) this.incWeekNo(-1);
            },
            getDayDate(day) {
                let datenumber = parseInt(this.dayDate[day]);
                let nth = "th";
                switch(datenumber) {
                    case 1:
                    case 21:
                    case 31:
                        nth = "st";
                        break;
                    case 2:
                    case 22:
                        nth = "nd";
                        break;
                    case 3:
                    case 23:
                        nth = "rd";
                        break;
                }
                return  datenumber + nth;//"02 Jun";
            },
            setWeekNumber() {
                if (this.weekNumber !== null) {
                    this.setWeekToNumber(this.weekNumber);
                }
                this.weekNumber = this.academicweeks?.anyWeek ? parseInt(this.academicweeks.anyWeek[0].Week) : 0;
                return this.weekNumber;
            },
            setWeekRange() {
                let weekStart = false;
                this.weekMonthOf = "";
                if (this.academicweeks.anyWeek) {
                    weekStart = this.academicweeks?.anyWeek ? this.academicweeks.anyWeek[0].WeekStart : false;
                    let dDates = this.academicweeks.anyWeek[0].dayDates || [];
                    let initialMonthName = "";
                    let monthName = "";
                    let endMonthName = "";
                    let datesLen = dDates.length;
                    for (let i = 0; i < datesLen; i++) {
                        let aDate = new Date(dDates[i]);
                        monthName = aDate.toLocaleString('default', { month: 'short' });
                        if (initialMonthName === "") {
                            initialMonthName = monthName;
                        }
                        if (this.weekMonthOf === "") {
                            this.weekMonthOf = monthName + " '";
                            this.weekMonthOf += (aDate.getYear() - 100);
                        }
                        let dString = " " + aDate.getDate().toString().padStart(2, '0');
                        if (monthName !== initialMonthName && (i === datesLen - 1)) {
                            endMonthName = " / " + monthName + " '" + (aDate.getYear() - 100);
                            this.weekMonthOf += endMonthName;
                            initialMonthName = monthName;
                        }
                        this.dayDate[i] = dString;
                    }
                }
                return weekStart;
            },
            setDateFromWeekNo() {
                //console.log("in setDateFromWeekNo " + this.weekNumber);
                //this.setWeekToNumber(this.weekNumber);
                let weekStart = this.setWeekRange();
                if (weekStart !== false) {
                    this.weekDate = "Week starting " + weekStart; //"Display date for the week number - To be set";
                } else {
                    this.weekDate = "Error getting week start date";
                }
                return this.weekDate;
            },
            async initialiseUI() {
                this.UIInitialised = false;
                try {
                    await this.getWeeks();
                } catch (error) {
                    console.log("Weeks error: ", error);
                    this.tterrors |= 2;
                    this.showErrorAlert('Error: Unable to get week numbers');
                }
                try {
                    await this.getTTData();
                } catch (error) {
                    this.tterrors |= 4;
                    this.showErrorAlert('Error: Unable to fetch Timetable Data');
                }
                try {
                    this.setWeekNumber();
                } catch (error) {
                    this.tterrors |= 8;
                    //console.error("Couldn't setWeekNumber");
                    this.showWarningAlert('Warning: Unable to setWeekNumber');
                }
                try {
                    await this.getCards(this.$route.name, true);
                } catch (error) {
                    //console.error("Couldn't getCards");
                    //this.showWarningAlert('Warning: Unable to retrieve cards');
                }
                this.UIInitialised = true;
            },
            initTTData: function() {
                this.lastActivityEndTime = null;
                this.activitiesPerDay = [];
                // set all hours of all days to an empty object ready to be repopulated
                for (let d = 0; d < 7; d++) {
                    this.activitiesPerDay[d] = 0;
                    this.ttdata[d] = []; //{};
                    for (let h = this.firstHourOfDay; h <= this.lastHourOfDay; h++) {
                        this.ttdata[d][h] = []; //{};
                    }
                }
                this.lastActivityEndTime = null;
                return this.ttdata;
            },
            buildTTArray: function() {
                /**
                 * TimeTable has been refreshed - page load or week change
                 * Build user-friendly array of activities this week
                 */
                this.initTTData();
                let tt = this.timetabledata.thisweek;

                let ttLen = tt.length; //this.timetabledata.thisweek.length;
                //console.log("tt: ", tt);
                if (ttLen) {
                    tt.sort((a, b) => a.ActualDateMoment > b.ActualDateMoment ? 1 : ((b.ActualDateMoment > a.ActualDateMoment) ? -1 : 0));
                    //console.log("tt: ", tt);
                }

                /**
                 * 0 {
                 *     "Activity_Hostkey": "#SPLUS1C2242",
                 *     "DayInWeek": "1",
                 *     "YearWeek": "7",
                 *     "TimetableWeek": "24",
                 *     "Activity": [{
                 *             "Hostkey": "#SPLUS1C2242",
                 *             "Name": "GEO2111/S02/19",
                 *             "Description": "Doing Human Geography Research: Theory and Practice",
                 *             "ScheduledStartTime": "1900-01-01T14:00:00+00:00",
                 *             "ScheduledEndTime": "1900-01-01T15:00:00+00:00",
                 *             "StartDate": "2024-02-06T00:00:00+00:00",
                 *             "Duration": "2",
                 *             "ActivityType_Name": "S",
                 *             "WeekRangeLabel": "23-25",
                 *             "StartHour": 14,
                 *             "StartMinute": 0
                 *         }
                 *     ]
                 * }
                 *
                 * Build Array of a[dayofweek][hourofday]
                 * ?? do we need half hours (StartMinute) maybe css set padding top to 0/15/30/45 (minutes)
                 */
                this.hasWeekendActivity = false;
                //console.log("tt", tt);
                //console.log("ttLen" + ttLen);
                let processingStr = "";
                let cntz = 0;
                for (let cnt = 0; cnt < ttLen; cnt++) {
                    let day = parseInt(tt[cnt].DayInWeek);
                    let hour = parseInt(tt[cnt].Activity[0].StartHour);
                    let slot = parseInt(tt[cnt].Activity[0]?.StartMinute) || 0; // - was going to be used as an array indicator
                    if (slot === 30) {
                        slot = 1;
                    }
                    if (!Array.isArray(this.ttdata[day][hour]) || this.ttdata[day][hour] === undefined) {
                        this.ttdata[day][hour] = [];
                    }
                    if (!Array.isArray(this.ttdata[day][hour][slot]) || this.ttdata[day][hour][slot] === undefined) {
                        this.ttdata[day][hour][slot] = [];
                    }
                    if (day >= 0 && hour >= 0) {
                        let ttEntry = tt[cnt];
                        let obj = {}
                        if (ttEntry.Activity[0]) {
                            //console.log("Fixed start and end dates: " + ttEntry.Activity[0].fixedStartDT + " " + ttEntry.Activity[0].fixedEndDT);
                            if (day > 4) {
                                this.hasWeekendActivity = true;
                            }
                            obj.StartHour = hour;
                            obj.StartMin = ttEntry.Activity[0]?.StartMinute || 0;
                            obj.EndHour = ttEntry.Activity[0]?.EndHour || 0;
                            obj.EndMinute = ttEntry.Activity[0]?.EndMinute || 0;
                                //console.log("StartEnds: " + obj.StartHour+":"+obj.StartMin + " " + obj.EndHour+":"+obj.EndMinute);
                            obj.StartTime = obj.StartHour.toString().padStart(2, '0') + ":" + obj.StartMin.toString().padStart(2, '0');
                            obj.EndTime = obj.EndHour.toString().padStart(2, '0') + ":" + obj.EndMinute.toString().padStart(2, '0');

                                //console.log("Starts: " + obj.StartHour+":"+obj.StartMin + " Ends: " + obj.EndHour+":"+obj.EndMinute + " EndTime: " + obj.EndTime);

                            if (day >= 0) {
                                processingStr += this.weekDays[day] + " " + obj.StartTime + ", ";
                            }
                            obj.Name = ttEntry.Activity[0]?.Name || "Missing";
                            obj.Duration = ttEntry.Activity[0].Duration || 1;

                            //console.log("Time and duration: " + obj.StartTime + " " + obj.Duration);

                            if (ttEntry.Activity[0].actModule) {
                                obj.ModuleCode = ttEntry.Activity[0]?.actModule[0]?.Module_Hostkey || "????"; // Module Code Missing
                                obj.Module = this.getModuleInfo(obj.ModuleCode);
                            } else {
                                obj.Module = this.getModuleInfo("");
                            }
                            // - fillers for activity details displayed in getActivityDetails() popup. Shouldn't be needed, sorted in store
                            obj.Location = ttEntry.Activity[0].Location || {Description: "Location not set",};
                            obj.StaffMember = ttEntry.Activity[0].StaffMember || {Staff_Name: "Staff member not set",};
                            obj.AllStaff = ttEntry.Activity[0].AllStaff;
                            obj.ActivityDate = ttEntry.ActualDate;
                            obj.ActivityType = ttEntry.Activity[0].ActivityType_Name;
                            obj.ActivityLong = this.act_translate(obj.ActivityType);
                            obj.MomentStart = moment.tz(ttEntry.Activity[0].fixedStartDT, "Europe/London");//.unix();
                                //console.log("obj.MomentStart: " + obj.MomentStart);
                                //console.log("ttEntry.Activity[0].fixedStartDT: " + ttEntry.Activity[0].fixedStartDT);
                            obj.MomentEnd = moment.tz(ttEntry.Activity[0].fixedEndDT, "Europe/London");//.unix();
                                //console.log("obj.MomentEnd: " + obj.MomentEnd);
                                //console.log("ttEntry.Activity[0].fixedEndDT: " + ttEntry.Activity[0].fixedEndDT);
                            obj.MomentEnd24 = moment.tz(ttEntry.Activity[0].fixedEndDT, "Europe/London").format("HH:mm");//.unix();
                            //console.log("start: " + obj.MomentStart + " end " + obj.MomentEnd + " end 24 " + obj.MomentEnd24);
                            obj.EndTimeAdded = obj.MomentStart.add(ttEntry.Activity[0].Duration * 30, 'minutes').format("hh:mm");
                            obj.DurationHours = ttEntry.Activity[0].Duration  / 2 + " hr";
                            obj.Description = ttEntry.Activity[0].Description;
                            this.activitiesPerDay[day] += 1;
                        } else {
                            obj.Name = "Missing Name";
                            obj.Duration = 1;
                            obj.ModuleCode = "";
                            obj.Module = this.getModuleInfo("");
                            obj.Location = {
                                Description: "-",
                            };
                            obj.StaffMember = {
                                Staff_Name: "-",
                            };
                        }

                        obj.isFake = false;

                        this.ttdata[day][hour][slot].push(obj);
                    } else {
                        //this.ttdata[day][hour] = null;
                        //this.ttdata[day][hour] = null;
                    }
                    cntz++;
                }
                if (this.hasWeekendActivity) {
                    this.weekDays = this.longWeek; //["M", "T", "W", "T", "F", "S", "S"];
                } else {
                    this.weekDays = this.shortWeek; //["M", "T", "W", "T", "F"];
                }
                this.setDateFromWeekNo();
            },
            isSevenDayWeek() {
                return this.hasWeekendActivity;
            },
            setDayWidth(noWeekend = null) {
                if (noWeekend === null) {
                    noWeekend = this.hasWeekendActivity;
                }
                let dayCols = document.getElementsByClassName("tt-day");
                if (dayCols.length > 0) {
                    //console.log("Day cols:", dayCols);
                    for (let i = 0; i < dayCols.length; i++) {
                        if (noWeekend) {
                            dayCols[i].classList.add("wideDay");
                        } else {
                            dayCols[i].classList.remove("wideDay");
                        }
                    }
                } else {
                    //console.log("Elements don't exist tho");
                }
            },
            updateTTData: function() {
                this.initTTData();
            },
            getTTArray: function() {
                //console.log("in getTTArray " + this.weekNumber );
                this.updateTTData();
                //console.log('TT Data ' + this.ttdata);
                return this.ttdata;
            },
            gotoWeekNo: function(whichWeek) {
                this.weekNumber = whichWeek;
                this.closeActivityDetails();
                this.setWeekNumber();
                this.setWeekRange();
                this.updateTTData();
            },
            incWeekNo: function(byhowmuch) {
                //console.log("Old weeknumber: " + this.weekNumber);
                if(byhowmuch < 0 && (this.weekNumber + byhowmuch) <= 0) {
                    this.weekNumber = 0;
                } else if(byhowmuch > 0 && (this.weekNumber + byhowmuch) >= 52) {
                    this.weekNumber = 52;
                } else {
                    this.weekNumber += byhowmuch;
                    this.closeActivityDetails();
                    this.setWeekNumber();
                    this.setWeekRange();
                    this.updateTTData();
                }
            },
            anythingThisHour(dayOfWeek, hourOfDay, textVer = false, stack = 0) {
                //console.log("Hour? " + dayOfWeek);
                dayOfWeek = parseInt(dayOfWeek);
                hourOfDay = parseInt(hourOfDay);
                let hasActivity = false;
                if (this.ttdata && this.ttdata[dayOfWeek][hourOfDay].length) {
                    hasActivity = true;
                }
                return hasActivity;
            },
            hasLocation(location) {
                return typeof location === "string" && location !== "-";
            },
            hasTTEntry(dayOfWeek, hourOfDay, minuteSlot) {
                if (this.ttdata && this.ttdata[dayOfWeek][hourOfDay]) {
                    //console.log(dayOfWeek + ":" + hourOfDay, this.ttdata[dayOfWeek][hourOfDay].length);
                }
                dayOfWeek = parseInt(dayOfWeek);
                hourOfDay = parseInt(hourOfDay);
                minuteSlot = parseInt(minuteSlot);
                let retVal = false;
                if (this.ttdata && this.ttdata[dayOfWeek][hourOfDay][minuteSlot] && this.ttdata[dayOfWeek][hourOfDay][minuteSlot].length && Object.keys(this.ttdata[dayOfWeek][hourOfDay][minuteSlot]).length) {
                    retVal = true;
                }
                return retVal;
            },
            getSlots() {
                return [0,1];
            },
            getTTEntries(dayOfWeek, hourOfDay, minuteSlot) {
                /**
                 * use this to read the timetable array[day][hour] and return activity if any
                 * Object.keys(userDetails).length
                 * Object.keys(this.ttdata[dayOfWeek][hourOfDay]).length
                 */
                hourOfDay = parseInt(hourOfDay);
                dayOfWeek = parseInt(dayOfWeek);
                minuteSlot = parseInt(minuteSlot);
                let retVal = false;
                let tStart = Date.now();
                let logit = false;
                if (this.ttdata && this.ttdata[dayOfWeek][hourOfDay].length > 0) { // && Object.keys(this.ttdata[dayOfWeek][hourOfDay]).length) {
                    // first get how many are on the hour, and how many start at half past
                    let howManyOnTheHour = 0;
                    let howManyAtHalfPast = 0;
                    // - minuteSlot is either 0 (H:00) or 1 (H:30)
                    for (const slot in this.ttdata[dayOfWeek][hourOfDay]) {
                        for (const idx in this.ttdata[dayOfWeek][hourOfDay][slot]) {
                            if (parseInt(this.ttdata[dayOfWeek][hourOfDay][slot][idx].StartMin) === 0) {
                                howManyOnTheHour++;
                            } else {
                                howManyAtHalfPast++;
                            }
                        }
                    }
                    let cntOnTheHour = 0;
                    let cntHalfPast = 0;
                    let cnt = 0;
                    let entries = this.ttdata[dayOfWeek][hourOfDay].length;
                    for (const slot in this.ttdata[dayOfWeek][hourOfDay]) {
                        for (const idx in this.ttdata[dayOfWeek][hourOfDay][slot]) {
                            if (parseInt(this.ttdata[dayOfWeek][hourOfDay][slot][idx].StartMin) === 0) {
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].id = cntOnTheHour;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].entries = howManyOnTheHour;
                                let w = 100 / howManyOnTheHour;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].width = w;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].posLeft = w * cntOnTheHour;
                                cntOnTheHour++;
                            } else if (parseInt(this.ttdata[dayOfWeek][hourOfDay][slot][idx].StartMin) === 30) {
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].id = cntHalfPast;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].entries = howManyAtHalfPast;
                                let w = 100 / howManyAtHalfPast;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].width = w;
                                this.ttdata[dayOfWeek][hourOfDay][slot][idx].posLeft = w * cntHalfPast;
                                cntHalfPast++;
                            }
                            //this.ttdata[dayOfWeek][hourOfDay][slot][idx].EndTime = this.ttdata[dayOfWeek][hourOfDay][slot][idx].fixedEndDT;
                            //console.log("Slot: " + slot);
                            // - console.log("slots: ", this.ttdata[dayOfWeek][hourOfDay]);
                            // - will be null first time round every day
                            if (this.lastActivityEndTime) {
                                //console.log(this.lastActivityEndTime);
                                if (this.lastActivityEndTime > this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentStart) {
                                    let a = new Date(this.lastActivityEndTime * 1000);
                                    let b = new Date(this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentStart * 1000);
                                    /* console.log(a, b);
                                    console.log("*!*!*!*!*!*!*!*! Overlap !*!*!*!*!*!*!*!*!*");
                                    console.log(this.lastActivityEndTime + " " + this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentStart); */
                                }
                                if (this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentEnd > this.lastActivityEndTime) {
                                    this.lastActivityEndTime = this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentEnd;
                                }
                            } else {
                                this.lastActivityEndTime = this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentEnd;
                            }
                            //this.lastActivityEndTime = this.ttdata[dayOfWeek][hourOfDay][slot][idx].MomentEnd;
                        }
                    }
                    // - console.log("slots: ", this.ttdata[dayOfWeek][hourOfDay]);
                    // - console.log("last activity time: " + this.lastActivityEndTime);
                    //this.lastActivityEndTime = hourOfDay;
                    retVal = this.ttdata[dayOfWeek][hourOfDay][minuteSlot];
                    this.currentEntry = retVal;
                }
                // - console.log("retVal ", retVal);
                return retVal;
            },
            getActivity(dayOfWeek, hourOfDay, minSlot, entryIndex) {
                /**
                 * this is a nice idea, use filter but seems overly complicated and inefficient
                 * being called for every hour of every day
                 */
                /*
                let activity = this.weekActivities.filter(function(activity){
                    let aDiW = parseInt(activity.DayInWeek);
                    let aSH = parseInt(activity.Activity[0].StartHour);
                    let aSM = parseInt(activity.Activity[0].StartMinute);
                    let DoW = parseInt(dayOfWeek);
                    let HoD = parseInt(hourOfDay);
                    let MoH = parseInt(minOfHour);
                    let res = aDiW === DoW && aSH === HoD && aSM === MoH;
                    if (res) {
                        console.log(res);
                    }
                    return res;
                });
                 */
                let activity;
                try {
                    activity = this.ttdata[dayOfWeek][hourOfDay][minSlot][entryIndex];
                    //console.log("Filtered Activity: ", activity);
                } catch (e) {
                    activity = null;
                }
                return activity;
            },
            async getActivityDetails(e) {
                e.stopPropagation();
                this.closeActivityDetails();
                let clickTarget;
                let searchNode = e.target;
                let targetFound = false;
                let searchCount = 0;
                while (searchNode.dataset.day === undefined) {
                    searchNode = searchNode.parentNode;
                }
                if (searchNode.dataset.day !== undefined) {
                    targetFound = true;
                    clickTarget = searchNode;
                } else {
                    return;
                }
                /*
                if (e.target.dataset.day !== undefined) {
                    clickTarget = e.target;
                } else if (e.target.parentNode.dataset.day !== undefined ){ // this handles clicking on the header
                    clickTarget = e.target.parentNode;
                } else {
                    return;
                }
                */
                let day = clickTarget.dataset.day;
                let hour = clickTarget.dataset.hour;
                let min = clickTarget.dataset.min;
                let index = clickTarget.dataset.id
                let containerBlock = document.getElementById("hourblock-" + day + "-" + hour);

                // make Integers from possible strings
                let iDay = parseInt(day);
                let iHour = parseInt(hour);
                let iMin = parseInt(min);
                let iIndex = parseInt(index);
                let Activity = this.getActivity(iDay, iHour, iMin, iIndex);

                // Get activity location to display the pop-up over
                let activityDetailsPopup = document.getElementById("activity-details");
                let targetTop = clickTarget.offsetTop;
                targetTop = containerBlock.offsetTop;

                // - show the popup
                activityDetailsPopup.classList.remove("nodisplay");

                // - set the data for the Activity Details popup
                await this.populateActivityDetails(Activity);

                activityDetailsPopup.classList.remove("big-bottom");
                activityDetailsPopup.classList.remove("negative-ml");



                // - set horizontal position of popup
                //let popupLeft = clickTarget.offsetLeft + "px";

                let popupLeft = containerBlock.offsetLeft + "px";

                //let clickTargetRect = clickTarget.getBoundingClientRect(); // <-- what we clicked on
                let clickTargetRect = containerBlock.getBoundingClientRect(); // <-- what we clicked on
                let popupRect = activityDetailsPopup.getBoundingClientRect(); // <-- the popup with the details

                if (parseInt(day) > 2) { // Thursday onward, display popup to the left of the day entry
                    let popupWidth = popupRect.width;
                    let clickTargetWidth = clickTargetRect.width;
                    //popupLeft = (clickTarget.offsetLeft - popupWidth + clickTargetWidth) + "px";
                    popupLeft = (containerBlock.offsetLeft - popupWidth + clickTargetWidth) + "px";

                    activityDetailsPopup.classList.add("negative-ml");
                }
                activityDetailsPopup.style.left = popupLeft;

                // - show the popup
                //activityDetailsPopup.classList.remove("nodisplay");
                clickTarget.classList.add("glow-block");
                clickTarget.classList.add("dropshadow");

                // - set vertical pos of popup
                activityDetailsPopup.style.top = targetTop + "px";


                // - listen for clicks/touches anywhere to close the popup
                document.addEventListener("click", this.closeActivityDetails);
                this.ActivityDetailsShowing = true;
            },
            clearExtraClass(classToRemove) {
                let activityDivs = Array.from(document.getElementsByClassName(classToRemove));
                activityDivs.forEach((element) => element.classList.remove(classToRemove));
            },
            closeActivityDetails() {
                if (this.ActivityDetailsShowing === true) {
                    try {
                        document.getElementById("activity-details").classList.add("nodisplay");
                        document.removeEventListener("click", this.closeActivityDetails);
                        this.ActivityDetailsShowing = false;
                        this.clearExtraClass("dropshadow");
                        this.clearExtraClass("glow-block");
                    } catch (e) {
                        // - do nothing
                    }
                }
            },
            populateActivityDetails(Activity) {
                /**
                 * Populate the output on the popup when user taps on a timetable activity entry.
                 *
                 */
                let allStaff = this.getAllStaff(Activity, "div"); // one per line in pop-up
                this.ActivityDetails = [
                    {
                        Name: "Module Code",
                        Val: Activity.ModuleCode,
                        Show: false
                    },
                    {
                        Name: "Name",
                        Val: Activity.Name,
                        Show: true
                    },
                    {
                        Name: "Description",
                        Val: Activity?.Description === undefined ? "" : Activity.Description,
                        Show: true
                    },
                    {
                        Name: "Location",
                        Val: Activity.Location.Name + " - " + Activity.Location.Description + "<br />" + Activity.Location.Link,
                        Show: true
                    },
                    {
                        Name: "Type",
                        Val: Activity.ActivityLong,
                        Show: true
                    },
                    {
                        Name: "Staff Members",
                        Val: allStaff, /* Activity.StaffMember.Staff_Name + "<br />" + allStaff , *//* .StaffMember.Staff_Description, */
                        Show: true
                    },
                    {
                        Name: "Date",
                        Val: Activity.ActivityDate,
                        Show: true
                    },
                    {
                        Name: "Start time",
                        Val: Activity.StartHour.toString().padStart(2, '0') + ":" + Activity.StartMin.toString().padStart(2, '0'),
                        Show: true
                    },
                    {
                        Name: "Duration",
                        Val: Activity.Duration * 30 + " mins (" + Activity.Duration / 2 + " hr)",
                        Show: true
                    },
                ];
            },
            getAllStaff(anActivity, element = "span") {
                let retval = "";
                let myClass = "";
                for (let idx in anActivity.AllStaff) {

                    retval += "<" + element + " class=\"staff-list stripe-" + this.oddOrEven(idx) + "\" >" + anActivity.AllStaff[idx].Staff_Name + "</" + element + ">";
                }
                return retval;
            },
            oddOrEven(num) {
                let retVal = "odd";
                if (num % 2 === 0) {
                    retVal = "even";
                }
                return retVal;
            }
        }
    };
</script>

<style scoped>
    @import '../../assets/mytimetable.css';
</style>
